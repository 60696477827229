<template>
<div class="contentBox">
   <a-button type="primary" @click="addUpload">新建</a-button>
  
   <div v-if="!isShow">
    <div v-for="item in data" :key="item.id" class="itemBox m-t1 flex al-center">
      <img class="img" :src="item.icon_url" alt="">
      {{ item.name }}
    </div>
    <div class="flex ju-end m-t1" v-show="data.length > 0">
      <a-pagination
      :total="total"
      :show-total="total => `共 ${total} 条`"
      :page-size="pageSize"
      @change="change"
      v-model:current="current"
    />
    </div>
   </div>
   <div v-else>
    1111
   </div>
  <a-modal v-model:visible="visible" title="上传" @ok="handleOk" destroyOnClose  :confirmLoading="confirmLoading">
   <UploadModal @loading="loadingStatus" @success="success" ref="UploadModalRef"></UploadModal>
  </a-modal>

</div>
</template>

<script setup>
import UploadModal from './uploadModal'
import { onMounted, ref } from 'vue'
import { cozeV1Datasets } from '@/api/repository/repository'
import storage from '@/utils/storage';
import { reactive } from 'vue';



const visible = ref(false)
const data = ref([])
const isShow = (false)
const UploadModalRef = ref(null)
const total = ref(10)
const pageSize = ref(10)
const current = ref(1)
const confirmLoading = ref(false)

const loadingStatus = (e) => {
  confirmLoading.value = e
}
const change = (e) => {
  current.value = e
  getList()
}

const success = () => {
  visible.value = false
  getList()
}
const handleOk = () => {
  UploadModalRef.value.submit()
}
const addUpload = () => {
  visible.value = true
}
const getList = () => {
 let accessToken = storage.get('access_token_coze') ? storage.get('access_token_coze') : ''
  cozeV1Datasets({
    pageNum:current.value,
    pageSize:pageSize.value,
    spaceID:'',
    accessToken:accessToken
  }).then( res => {
    if(res.code !== 10000) return
    data.value = res.data.items
    total.value = res.data.total
  })
}
onMounted(() => {
  getList()
})
</script>

<style lang="less" scoped>
.contentBox{
  padding: 20px;
  min-height: 600px;
  background: #fff;
}

.itemBox{
  width: 100%;
  height: auto;
  padding: 10px ;
  border: 1px solid #eee;
  border-radius: 3px;
}
.itemBox:hover{
  border-color: #188BFF;
}
.img{
  width: 22px;
  height: 24px;
  margin-right: 15px;
  border-radius: 2px;
}
</style>