<template>
  <div>
    <!-- <a-upload v-model:file-list="fileList"  list-type="picture" multiple name="avatar" class="avatar-uploader" :show-upload-list="true" :before-upload="beforeUpload" :remove="removeChange" >
            <a-button :loading="loading"  type="primary">上传</a-button>
        </a-upload> -->
    <a-upload-dragger v-model:fileList="fileList" list-type="picture" name="avatar" multiple :before-upload="beforeUpload" :remove="removeChange">
      <p class="ant-upload-drag-icon">
        <inbox-outlined></inbox-outlined>
      </p>
      <div class="ant-upload-text">点击上传或拖拽文档到这里</div>

    </a-upload-dragger>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { message } from 'ant-design-vue'
import { invoiceList, fileUpload as upload } from '@/api/marketing/modList'
import { cozeDocumentCreate } from '@/api/repository/repository.js'
import { InboxOutlined } from '@ant-design/icons-vue';
import storage from '@/utils/storage';
const fileList = ref([])
const loading = ref(false)
const fileListImg = ref([])

const emit =  defineEmits(['success','loading'])

const beforeUpload = (file) => {
  fileListImg.value.push(file)
  return false
}
let idx = 0
const submit = () => {

  convertToBase64(fileListImg.value[idx],fileListImg.value[idx].name)
}

function convertToBase64 (file,name) {
  const reader = new FileReader();
  // 文件读取完成时触发
  reader.onload = (event) => {
    const base64 = event.target.result; // 获取 Base64 编码
    console.log('Base64:', base64);
    getCozeDocumentCreate(base64,name)
  };

  // 文件读取失败时触发
  reader.onerror = (error) => {
    console.error('文件读取失败:', error);
  };

  // 开始读取文件
  reader.readAsDataURL(file);
}
const getCozeDocumentCreate = (base64,name) => {
  loading.value = true
  emit('loading',loading.value)
  let accessToken = storage.get('access_token_coze') ? storage.get('access_token_coze') : ''
  cozeDocumentCreate({
    accessToken:accessToken,
    fileBase64:base64,
    name:name,
    datasetID:'7474851899059421225'
  }).then(res => {
    if(res.code !== 10000) return
    idx++
    if(fileListImg.value.length > idx){
      submit()
    }else{
      idx = 0
      message.success('上传成功')
      loading.value = false
      emit('loading',loading.value)
      emit('success');
    }
  }).finally(() => {
  
  })
}
// const key = 'updatable'
// const updateBatch = (file) => {
//   loading.value = true
//   message.loading({ content: '上传中..', key })
//   const formData = new FormData()
//   formData.append('file', file)
//   upload(formData).then((res) => {
//     loading.value = false
//     if (res.code === 10000) {
//       fileListImg.value.push(res.data)
//       message.success({ content: '上传成功', key, duration: 2 })
//     } else {
//       message.error({ content: '上传失败', key, duration: 2 })
//     }
//   })
// }
const removeChange = (file) => {
  fileList.value.forEach((item, index) => {
    if (item.name === file.originFileObj.name) fileListImg.value.splice(index, 1)
  })
}
defineExpose({
  submit,
  
})
</script>

<style lang="less" scoped>
.ant-upload-text {
  font-size: 14px;
  color: #666;
}
</style>